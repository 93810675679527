<template>
  <div class="tag-view">
    <div class="div">
      {{ tag.name }}
    </div>
    <span
      @click="showUpdateModal()"
      style="color: #ffc107; cursor: pointer; font-size: 1.8rem"
      ><i class="mdi mdi-pencil-circle"></i
    ></span>
    <span
      @click="confirm()"
      style="color: #dc3545; cursor: pointer; font-size: 1.8rem"
      ><i class="mdi mdi-delete-circle"></i
    ></span>
  </div>

  <b-modal
    v-model="updateModal"
    id="modal-ml"
    size="ml"
    title="Modifier le tag"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <form>
        <div class="form-group row mb-4">
          <label for="projectname" class="col-form-label">Titre du tag</label>
          <div class="col-lg-12">
            <input
              id="projectname"
              name="projectname"
              type="text"
              class="form-control"
              v-model="tagsInfos.titre"
              placeholder="Entrez le nom du tag"
            />
          </div>
        </div>
      </form>

      <div style="display: flex; justify-content: center">
        <addButton :text="'Modifier'" @click="updateTag"></addButton>
      </div>
    </div>
  </b-modal>
  <loaderProgress :visible="process" :progress="progress" :title="titre" />
</template>

<script>
import Swal from "sweetalert2";
import { Api } from "../../../../helpers";
import { Erreur } from "../../../../helpers/error";
import addButton from "../../../../components/widgets/addButton.vue";
import loaderProgress from "../../../../components/widgets/loaderProcess.vue";
export default {
  components: {
    addButton,
    loaderProgress,
  },
  data() {
    return {
      process: false,
      progress: 0,
      titre: "",
      updateModal: false,
      tagsInfos: {
        titre: "",
      },
    };
  },
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.tagsInfos.titre = this.tag.name;
  },
  methods: {
    showUpdateModal() {
      this.updateModal = true;
    },

    confirm() {
      Swal.fire({
        title: "Voulez vraiment supprimer ce tag ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteTag();
        }
      });
    },
    deleteTag() {
      Api.delete("/streamvodv2/rest/v2/tag/delete/" + this.tag.id)
        .then(() => {
          Swal.fire("Deleted!", "Tag supprimé avec succès", "success");
          location.reload();
        })
        .catch((error) => {
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
    updateTag() {
      this.process = true; // Set the process variable to true
      this.updateModal = false; // Hide the modal
      this.process = true;
      
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Modification du tag";

      Api.put(`/streamvodv2/rest/v2/tag/update/${this.tag.id}`, {
        name: this.tagsInfos.titre,
      }, {onUploadProgress})
        .then(function (response) {
          Swal.fire("Update!", "Tag modifié avec succès", "success");
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
};
</script>

<style></style>
